import React from "react"
import "../styles/mentionLegale.css"
import yamlData from "../content/mentionlegale/mentionLegale.yaml"
import Layout from "../components/common/layout"
import { Container } from "react-bootstrap"
import Hr from "../components/common/hr"
import Seo from "../components/common/seo"

const MentionLegales = () => (
  <Layout>
    <Seo
      title="Mentions légales - casse auto - epaviste Dulin"
      description="Mentions légale du site internet de l'épaviste Dulin"
    />
    <Container className="content-mention">
      <Hr />
      <section className="padding-mention">
        <article className="article-mention animated fadeInUp delay-0.2s">
          <h1 className="principal-title-mention">{yamlData.title}</h1>
          <h2 className="title-mention">{yamlData.subTitle}</h2>
          <p className="paragraphe">{yamlData.paragraphe} :</p>
          <p className="paragraphe">
            Éditeur et directeur de publication :
            <br />
            PIECES AUTO DULIN
            <br />
            Siège social :
            <br />
            27 AVENUE DU 8 MAI 1945
            <br />
            91100 CORBEIL-ESSONNES
            <br />
            Tél. : 07 49 38 70 47
            <br />
            Siret : 444182166
          </p>
          <p className="paragraphe">
            Réalisation et hébergement :
            <br />
            Bikach
            <br />
            Siège social :
            <br />
            125 rue Gabriel péri
            <br />
            91700, Sainte-genèvive-des-bois
            <br />
            Tél. : 07 49 38 70 47
            <br />
            Siret : 541256325
          </p>
        </article>

        <article className="article-mention animated fadeInUp delay-0.2s">
          <h2 className="title-mention">{yamlData.conditions.subTitle}</h2>
          <p className="paragraphe">{yamlData.conditions.paragraphe1}</p>
          <p className="paragraphe">{yamlData.conditions.paragraphe2}</p>
          <p className="paragraphe">{yamlData.conditions.paragraphe3}</p>
        </article>

        <article className="article-mention animated fadeInUp delay-0.2s">
          <h2 className="title-mention">{yamlData.description.subTitle}</h2>
          <p className="paragraphe">{yamlData.description.paragraphe1}</p>
          <p className="paragraphe">{yamlData.description.paragraphe2}</p>
          <p className="paragraphe">{yamlData.description.paragraphe3}</p>
        </article>

        <article className="article-mention animated fadeInUp delay-0.2s">
          <h2 className="title-mention">{yamlData.limitation.subTitle}</h2>
          <p className="paragraphe">{yamlData.limitation.paragraphe1}</p>
          <p className="paragraphe">{yamlData.limitation.paragraphe2}</p>
        </article>

        <article className="article-mention animated fadeInUp delay-0.2s">
          <h2 className="title-mention">{yamlData.propriete.subTitle}</h2>
          <p className="paragraphe">{yamlData.propriete.paragraphe1}</p>
          <p className="paragraphe">{yamlData.propriete.paragraphe2}</p>
        </article>

        <article className="article-mention animated fadeInUp delay-0.2s">
          <h2 className="title-mention">{yamlData.responsabilite.subTitle}</h2>
          <p className="paragraphe">{yamlData.responsabilite.paragraphe1}</p>
          <p className="paragraphe">{yamlData.responsabilite.paragraphe2}</p>
          <p className="paragraphe">{yamlData.responsabilite.paragraphe3}</p>
        </article>

        <article className="article-mention animated fadeInUp delay-0.2s">
          <h2 className="title-mention">{yamlData.donnees.subTitle}</h2>
          <p className="paragraphe">{yamlData.donnees.paragraphe1}</p>
          <p className="paragraphe">{yamlData.donnees.paragraphe2}</p>
          <p className="paragraphe">{yamlData.donnees.paragraphe3}</p>
          <p className="paragraphe">{yamlData.donnees.paragraphe4}</p>
          <p className="paragraphe">{yamlData.donnees.paragraphe5}</p>
          <p className="paragraphe">{yamlData.donnees.paragraphe6}</p>
        </article>

        <article className="article-mention animated fadeInUp delay-0.2s">
          <h2 className="title-mention">{yamlData.hypertextes.subTitle}</h2>
          <p className="paragraphe">{yamlData.hypertextes.paragraphe1}</p>
          <p className="paragraphe">{yamlData.hypertextes.paragraphe2}</p>
        </article>

        <article className="article-mention animated fadeInUp delay-0.2s">
          <h2 className="title-mention">{yamlData.lois.subTitle}</h2>
          <p className="paragraphe">{yamlData.lois.paragraphe1}</p>
          <p className="paragraphe">{yamlData.lois.paragraphe2}</p>
        </article>

        <article className="article-mention animated fadeInUp delay-0.2s">
          <h2 className="title-mention">{yamlData.lexique.subTitle}</h2>
          <p className="paragraphe">{yamlData.lexique.paragraphe1}</p>
          <p className="paragraphe">{yamlData.lexique.paragraphe2}</p>
        </article>

        <article className="article-mention animated fadeInUp delay-0.2s">
          <h2 className="title-mention">{yamlData.partenaire.subTitle}</h2>
          <p className="paragraphe">{yamlData.partenaire.paragraphe1}</p>
        </article>
      </section>
    </Container>
  </Layout>
)

export default MentionLegales
